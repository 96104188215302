import classnames from "classnames";

import { Container } from "react-bootstrap";

import Footer from "../Shared/Footer";
import NavbarSec from "../Shared/NavbarSec";

export default function Layout({ message, children }) {
  return (
    <div>
      <Container className={classnames({ "show-server-status": message })}>
        <NavbarSec message={message} />
      </Container>
      <div
        className={classnames({
          "show-server-status": message !== null,
        })}
      >
        {children}
        <Footer />
      </div>
    </div>
  );
}
