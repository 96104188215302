import React from "react";
import { useScrollTo } from "react-use-window-scroll";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";

export default function ScrollIndicator() {
  const scrollTo = useScrollTo();

  return (
    <Container>
      <Row>
        <Col md={{ span: 2, offset: 10 }}>
          <div style={{}}>
            <button
              className="scrollbtn"
              onClick={() => scrollTo({ top: 0, left: 0, behavior: "smooth" })}
            >
              <FontAwesomeIcon icon={faAngleUp} />
            </button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
