import {
  faAppStoreIos,
  faGooglePlay,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCheck,
  faGift,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faArrowRightToBracket,
  faCircle1,
  faCircle2,
  faCircle3,
  faCircle4,
  faCircle5,
  faCircle6,
  faCircle7,
  faCircle8,
  faClipboardUser,
  faMicrochip,
  faTags,
  faUserHelmetSafety,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React from "react";

import { Accordion, Button, Col, Container, Row } from "react-bootstrap";

import ScrollIndicator from "../Component/ScrollToTop";
import EliteCloud from "../Img/EliteCloud.png";
import EliteControl from "../Img/EliteControl.png";

const Upgrade = () => {
  return (
    <>
      <Container
        id="home"
        className="text-center"
        style={{ marginTop: "150px" }}
      >
        <Row>
          <Col xs={12}>
            <Row>
              <Col xs={12}>
                <h1 className="landhd">
                  <img src={EliteControl} width="50px" alt="icon" />
                  &nbsp; EliteControl APP End of Life
                </h1>
                <br />
                <br />
                <a
                  href="EliteControl EOL - EliteCloud Upgrade.pdf"
                  className="elitecontrol-shutdown-link"
                >
                  <span className="important">Click Here</span>
                  For full details on the EliteControl Server Shut-down
                </a>
              </Col>{" "}
            </Row>
            {/* <Row>
                <Col xs={12}>
                  <p className="stepsDesc" style={{ marginTop: "20px" }}>
                    EliteControl App End of Life{" "}
                  </p>
                </Col>
              </Row> */}
            <Row>
              <Col xs={12}>
                <p className="landNte">
                  After 7 years of service the
                  <strong> EliteControl APP has come to an end.</strong> Recent
                  server & smart device upgrades by other party’s means that the
                  EliteControl APP can no longer be maintained to an acceptable
                  standard.
                  <br />
                  <br />
                  Your existing alarm hardware is fully supported by our new
                  EliteCloud APP. Below is our step by step guide on 'How to
                  Upgrade', including EliteCloud 'Home Plan' free for 2 years.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {/* EliteCloud Overview */}

      <Container fluid className="overview" id="">
        <Row className="fontfeatrs overview">
          <Col sm={12}>
            <h2>
              {" "}
              <img
                src={EliteCloud}
                style={{ marginBottom: "3px" }}
                width="50px"
                alt="icon"
              />
              &nbsp;Upgrade to EliteCloud
            </h2>

            <Container className="text-center">
              <iframe
                src="https://www.youtube.com/embed/qAD7qpxsMTI"
                className="overviewVid"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </Container>
          </Col>
        </Row>
      </Container>

      {/* Steps */}
      <Container fluid className="fontawesmeCntrFld" id="fontawesmeCntrFld">
        <Row className="fontfeatrs">
          <Col>
            <div className="text-center">
              <h2 className="faq-title">How to Upgrade</h2>
              <p className="stepsDesc">
                Follow these upgrade steps to receive EliteCloud 'Home Plan'
                free for 2 years.
              </p>
            </div>
            <Container className="fontContnr">
              <Row>
                <Col xs={12} md={6} lg={3} className="stepItem">
                  <FontAwesomeIcon icon={faCircle1} className="numberIcon" />
                  <div className="fontfeatrsSec">
                    <div className="stepsIcons" size="sm">
                      <FontAwesomeIcon
                        className="nonClickable"
                        icon={faUserHelmetSafety}
                      />
                    </div>
                    <h6>
                      <strong>Upgrade Module</strong>
                    </h6>
                    <p>
                      Contact your preferred security installer to update (or
                      upgrade) your alarm network module<strong> OR </strong>
                      contact
                      <a className="mailLink" href="mailto: support@aap.co.nz">
                        {" "}
                        support@aap.co.nz{" "}
                      </a>
                      for a recommended installer in your area.
                    </p>
                  </div>
                </Col>
                <Col xs={12} md={6} lg={3} className="stepItem stepLink">
                  <FontAwesomeIcon icon={faCircle2} className="numberIcon" />

                  <div className="fontfeatrsSec">
                    <div className="stepsIcons" size="sm">
                      <FontAwesomeIcon
                        className="nonClickable"
                        icon={faMobileAlt}
                      />
                    </div>
                    <h6>
                      <strong>Get The APP</strong>
                    </h6>
                    <p>
                      Download the EliteCloud App on your smart device (Search
                      EliteCloud on App Store or Google Play)
                    </p>
                  </div>
                  <Row className="text-center">
                    <Col>
                      <a href="https://play.google.com/store/apps/details?id=nz.co.aap.elitecontrol&hl=en_NZ&gl=US">
                        <FontAwesomeIcon
                          className="appStore"
                          icon={faGooglePlay}
                        />
                      </a>
                    </Col>
                    <Col>
                      <a href="https://apps.apple.com/mm/app/elitecloud/id1508242548">
                        <FontAwesomeIcon
                          className="appStore"
                          icon={faAppStoreIos}
                        />
                      </a>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={6} lg={3} className="stepItem">
                  <a href="https://account.elitecloud.co.nz/register">
                    <FontAwesomeIcon icon={faCircle3} className="numberIcon" />
                    <div className="fontfeatrsSec">
                      <div className="stepsIcons" size="sm">
                        <FontAwesomeIcon
                          className="iconLink"
                          icon={faClipboardUser}
                        />
                      </div>
                      <h6>
                        <strong>Sign Up</strong>
                      </h6>
                      <p>
                        Register an account
                        <br /> with EliteCloud
                      </p>
                      <br />
                      <Button
                        className="upgradeButton"
                        style={{ marginTop: "10px" }}
                        variant="primary"
                      >
                        Sign Up
                      </Button>
                    </div>
                  </a>
                </Col>
                <Col xs={12} md={6} lg={3} className="stepItem">
                  <a href="https://account.elitecloud.co.nz/login">
                    <FontAwesomeIcon icon={faCircle4} className="numberIcon" />
                    <div className="fontfeatrsSec">
                      <div className="stepsIcons" size="sm">
                        <FontAwesomeIcon
                          className="iconLink"
                          icon={faArrowRightToBracket}
                        />
                      </div>
                      <h6>
                        <strong>Sign In</strong>
                      </h6>
                      <p>
                        Sign into your EliteCloud Account & <br />
                        open the 'Coupons' tab found in the sidebar.
                      </p>
                      <br />
                      <Button
                        className="upgradeButton"
                        style={{
                          marginTop: "-13px",
                          paddingLeft: "14px",
                          paddingRight: "14px",
                        }}
                        variant="primary"
                      >
                        Sign In
                      </Button>
                    </div>
                  </a>
                </Col>
              </Row>
              <hr className="stepsDivider" />
              <Row>
                <Col xs={12} md={6} lg={3}>
                  <FontAwesomeIcon icon={faCircle5} className="numberIcon" />
                  <div className="fontfeatrsSec">
                    <div className="stepsIcons" size="sm">
                      <FontAwesomeIcon
                        className="nonClickable"
                        icon={faMicrochip}
                      />
                    </div>
                    <h6>
                      <strong>Retrieve Mac & Serial</strong>
                    </h6>
                    <p>
                      Retrieve the MAC & Serial number from your old
                      EliteControl APP.
                    </p>
                  </div>
                </Col>
                <Col xs={12} md={6} lg={3}>
                  <FontAwesomeIcon icon={faCircle6} className="numberIcon" />
                  <div className="fontfeatrsSec">
                    <div className="stepsIcons" size="sm">
                      <FontAwesomeIcon className="nonClickable" icon={faTags} />
                    </div>
                    <h6>
                      <strong>Generate Coupon</strong>
                    </h6>
                    <p>
                      Enter the Mac Address & Serial Number into the 'Coupons{" "}
                      {">"} Generate' field found in you EliteCloud account
                      dashboard, then press the 'Generate' button. Providing the
                      details you entered are correct, your coupon code will now
                      display in the 'Generate' field.
                    </p>
                  </div>
                </Col>
                <Col xs={12} md={6} lg={3}>
                  <FontAwesomeIcon icon={faCircle7} className="numberIcon" />
                  <div className="fontfeatrsSec">
                    <div className="stepsIcons" size="sm">
                      <FontAwesomeIcon className="nonClickable" icon={faGift} />
                    </div>
                    <h6>
                      <strong>Redeem Coupon</strong>
                    </h6>
                    <p>
                      Press the 'Redeem' tab, enter the coupon code that you
                      received in step 5, then press the 'Redeem' button. Follow
                      the prompts to complete the transaction.
                    </p>
                  </div>
                </Col>
                <Col xs={12} md={6} lg={3}>
                  <a href="https://www.youtube.com/playlist?list=PLMUmQpAlaJtSLetMsS53RALkVkeuelleQ">
                    <FontAwesomeIcon icon={faCircle8} className="numberIcon" />
                    <div className="fontfeatrsSec">
                      <div className="stepsIcons" size="sm">
                        <FontAwesomeIcon className="iconLink" icon={faCheck} />
                      </div>
                      <h6>
                        <strong>You're All Set!</strong>
                      </h6>
                      <p>
                        Enjoy controlling & monitoring your alarm system from
                        the EliteCloud app.
                      </p>
                    </div>
                  </a>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>

      <Container className="text-center" style={{ marginTop: "55px" }}>
        <h2 className="faq-title">Frequently Asked Questions</h2>
      </Container>

      <Container>
        <Accordion style={{ marginTop: "50px", marginBottom: "100px" }}>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="faqHeader">
              Which plan does the 'EliteControl Upgrade Coupon' relate to?
            </Accordion.Header>
            <Accordion.Body className="faqContent">
              This 2 years free coupon can only be applied to the yearly or
              monthly 'Home Plan'. 'Multi, Enterprise & Installer' Plans are not
              included under this promotion.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              How long will my EliteControl App work for?
            </Accordion.Header>
            <Accordion.Body className="faqContent">
              We endeavour to keep the EliteControl APP server operational for a
              minimum of 1 year from the date of this notice, however some smart
              phones & network devices will become non-operational due to
              circumstances out of our control.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              How long is my coupon valid for after it has been generated?
            </Accordion.Header>
            <Accordion.Body className="faqContent">
              Coupons must be redeemed within 2 months of being generated. It is
              best to generate EliteCloud coupons after your preferred security
              installer has updated (or upgraded) your alarm network module.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              How do I know if I am eligible for the '2 year free Home Plan'
              coupon?
            </Accordion.Header>
            <Accordion.Body className="faqContent">
              Any 'EliteControl alarm network module' that is upgraded to
              EliteCloud is eligible for this coupon.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              Will my alarm stop working when the EliteControl APP service is
              closed?
            </Accordion.Header>
            <Accordion.Body className="faqContent">
              Your alarm will continue to operate as per normal, however it will
              no longer be controllable via the EliteControl APP.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              What if I have already upgraded to EliteCloud and have a current
              plan?
            </Accordion.Header>
            <Accordion.Body className="faqContent">
              If you upgraded your alarm network module to EliteCloud before
              this promotion was available then you can still follow steps 4 - 8
              (above) to redeem your '2 years free coupon'. In this instance the
              coupon will be applied to the 'Home Plan' in the next payment
              period.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>
              Does Arrowhead charge to update your module for it to become
              EliteCloud compatible?
            </Accordion.Header>
            <Accordion.Body className="faqContent">
              No, Arrowhead does not charge for this upgrade to your module.
              Arrowhead will also cover the freight charge to return the module
              to your address (NZ addresses only).{" "}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>
              Will an alarm installer charge to update my module to become
              EliteCloud compatible?
            </Accordion.Header>
            <Accordion.Body className="faqContent">
              It is recommended that your alarm is serviced every 2 - 3 years.
              If you are looking to get your system serviced then the installer
              may provide the update free of charge but you will need to discuss
              this directly with your installer.{" "}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>
              Who can update my module to become EliteCloud compatible?
            </Accordion.Header>
            <Accordion.Body className="faqContent">
              Anyone who is certified to handle 230V mains can remove the module
              from your system and send back to Arrowhead for a free update.{" "}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9">
            <Accordion.Header>
              How can I get in touch if I have any more questions?
            </Accordion.Header>
            <Accordion.Body className="faqContent">
              You can get in touch with our support team at{" "}
              <a className="mailLink" href="mailto: support@aap.co.nz">
                {" "}
                support@aap.co.nz .
              </a>{" "}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
      <div className="scrollTop">
        <ScrollIndicator />
      </div>
    </>
  );
};

export default Upgrade;
