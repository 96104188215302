import React,{Component} from 'react';
import '../App.css';
import {Container, Row, Col,ListGroup,Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFacebook,faLinkedin,faYoutube,faInstagramSquare } from '@fortawesome/free-brands-svg-icons'
import { Link } from "react-router-dom";
export default class Footer extends Component{
     

render(){
    return(

<div>
    <Container>
      <Row className="footerSec">
        {/* <Col><img className="logo" src={logo}/></Col>
        <Col>
          <ListGroup>
            <ListGroup.Item className="Fterbld">Product</ListGroup.Item>
            <ListGroup.Item>Features</ListGroup.Item>
            <ListGroup.Item>Integration</ListGroup.Item>
            <ListGroup.Item>Documnentation</ListGroup.Item>
          <ListGroup.Item>Pricing</ListGroup.Item>
          </ListGroup>
        </Col>
        <Col>
          <ListGroup>
          <ListGroup.Item  className="Fterbld">About</ListGroup.Item>
          <ListGroup.Item>Our Story</ListGroup.Item>
          <ListGroup.Item>Company</ListGroup.Item>
          <ListGroup.Item>Our Team</ListGroup.Item>
          <ListGroup.Item>Work With Us</ListGroup.Item>
          </ListGroup>
        </Col>
        <Col>
          <ListGroup>
            <ListGroup.Item  className="Fterbld">Resources</ListGroup.Item>
            <ListGroup.Item>Help Center</ListGroup.Item>
            <ListGroup.Item>Developer API</ListGroup.Item>
            <ListGroup.Item>Our Blog</ListGroup.Item>
            <ListGroup.Item>Status</ListGroup.Item>
            <ListGroup.Item>Sitemap</ListGroup.Item>
          </ListGroup>
        </Col>
        <Col>
          <ListGroup>
          <ListGroup.Item  className="Fterbld">Contact</ListGroup.Item>
          <ListGroup.Item>Advertising</ListGroup.Item>
          <ListGroup.Item>Press</ListGroup.Item>
          <ListGroup.Item>Partners</ListGroup.Item>
          <ListGroup.Item>Email</ListGroup.Item>
          </ListGroup>
      </Col> */}
    </Row><hr/>
    <Row className="socialBar">
      <Col xs={12} md={4}><p className="cpyrght">©2021 Arrowhead Alarm Products Limited. All right reserved</p></Col>
      <Col xs={12}  md={4}>
      <ListGroup horizontal>
           <p><Link to={{ pathname: "https://account.elitecloud.co.nz/privacy" }} target="_blank">Priavcy Policy</Link>&nbsp;&nbsp;|&nbsp;&nbsp;
           <Link to={{ pathname: "https://account.elitecloud.co.nz/terms" }} target="_blank">Terms & Conditions</Link> &nbsp;&nbsp;|&nbsp;&nbsp; 
             <Link to={{ pathname: "https://account.elitecloud.co.nz/cookiepolicy" }} target="_blank">Cookie Policy</Link></p>
         
          </ListGroup>
      </Col>
        <Col xs={12} md={4}> 
          <ListGroup horizontal>
          <Link to={{ pathname: "https://www.facebook.com/elitecontrolnz" }} target="_blank"> <ListGroup.Item> <FontAwesomeIcon icon={faFacebook} /></ListGroup.Item></Link>
          <ListGroup.Item><FontAwesomeIcon icon={faLinkedin} /></ListGroup.Item>
          <Link to={{ pathname: "https://www.instagram.com/elitecontrolnz/" }} target="_blank"> <ListGroup.Item><FontAwesomeIcon icon={faInstagramSquare} /></ListGroup.Item></Link>
          <Link to={{ pathname: "https://www.youtube.com/channel/UCYhukSNdB3GZb-XIoiB6qyg" }} target="_blank"> <ListGroup.Item><FontAwesomeIcon icon={faYoutube} /></ListGroup.Item></Link>
          </ListGroup>
        </Col>
    </Row>
    </Container>  
<Navbar bg="light"sticky="bottom">
 <Navbar.Brand href="#home"></Navbar.Brand>
</Navbar>
  
</div>

    );
};
}