import emailjs from "@emailjs/browser";

import Rating from "@mui/material/Rating";

import React, { useState } from "react";

import { Button, Card, Col, Form, Row } from "react-bootstrap";

import "../App.css";

const Feedback = ({ message }) => {
  const [rating, setRating] = useState(5);
  const [app, setApp] = useState(false);
  const [dashboard, setDashboard] = useState(false);
  const [other, setOther] = useState(false);
  const [all, setAll] = useState(false);
  const [name, setName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [feedbackDesc, setFeedbackDesc] = useState("");
  const [isContact, setContact] = useState(false);
  const [success, setSuccess] = useState(false);
  // const [captcha, setCaptcha] = useState(null);
  // const [verified, setVerified] = useState(false);

  // var verifyCallback = function (response) {
  //   console.log("Verifed");
  //   setVerified(true);
  //   setCaptcha(response);
  // };

  const packageFeedback = (e) => {
    e.preventDefault();

    let selectedProduct = "";

    if (app) {
      selectedProduct = "Apps";
    }

    if (dashboard) {
      selectedProduct = "Dashboard";
    }

    if (all) {
      selectedProduct = "All Products";
    }

    if (other) {
      selectedProduct = "Other";
    }

    let templateParams = {
      name: name,
      email: emailAddress,
      product: selectedProduct,
      rating: rating,
      feedback: feedbackDesc,
      contactCustomer: isContact,
    };

    console.log("Feedback:", templateParams);
    sendEmail(templateParams);
  };

  const sendEmail = (templateParams) => {
    emailjs
      .send(
        "service_gktsbte",
        "template_rv7hf2x",
        templateParams,
        "Ttkhnv-LRUKcZX4kY"
        // captcha
      )
      .then(
        (result) => {
          console.log(result.text);
          if (result.text === "OK") {
            setSuccess(true);
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const appsClick = () => {
    setApp(!app);
    setDashboard(false);
    setOther(false);
    setAll(false);
  };

  const dashboardClick = () => {
    setDashboard(!dashboard);
    setApp(false);
    setOther(false);
    setAll(false);
  };

  const allClick = () => {
    setApp(false);
    setDashboard(false);
    setOther(false);
    setAll(!all);
  };

  const otherClick = () => {
    setApp(false);
    setDashboard(false);
    setOther(!other);
    setAll(false);
  };

  return (
    <div className="feedbackContainer">
      {success ? (
        <h2 className="feedbackHeader">Thanks for your feedback!</h2>
      ) : (
        <>
          {" "}
          <div className="feedbackText">
            <h2 className="feedbackHeader">Send us your feedback!</h2>
            <h3 className="landNte">
              We strive to give you the best user experience.
            </h3>
          </div>
          <div className="feedbackForm">
            <Form onSubmit={packageFeedback}>
              <Form.Label className="feedbackItem">Full name</Form.Label>
              <Form.Control
                style={{ borderRadius: "10px" }}
                onChange={(event) => {
                  setName(event.target.value);
                }}
                required
              />
              <Form.Label className="feedbackItem">Email address</Form.Label>
              <Form.Control
                style={{ borderRadius: "10px" }}
                onChange={(event) => {
                  setEmailAddress(event.target.value);
                }}
                required
              />
              <Form.Label className="feedbackItem">
                Which product are you providing feedback on?
              </Form.Label>
              <Card className="feedbackProductCard">
                <Card.Body>
                  <Row>
                    <Col sm={3}>
                      <Card onClick={appsClick}>
                        <Card.Body
                          className={
                            app ? "selectedProduct" : "feedbackProductItem"
                          }
                        >
                          Apps
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm={3}>
                      {" "}
                      <Card onClick={dashboardClick}>
                        <Card.Body
                          className={
                            dashboard
                              ? "selectedProduct"
                              : "feedbackProductItem"
                          }
                        >
                          Dashboard
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm={3}>
                      {" "}
                      <Card onClick={allClick}>
                        <Card.Body
                          className={
                            all ? "selectedProduct" : "feedbackProductItem"
                          }
                        >
                          All
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm={3}>
                      {" "}
                      <Card onClick={otherClick}>
                        <Card.Body
                          className={
                            other ? "selectedProduct" : "feedbackProductItem"
                          }
                        >
                          Other
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Row>
                <Col sm={12}>
                  {" "}
                  <Form.Label className="feedbackItem">
                    How satisfied are you with our product?
                  </Form.Label>
                </Col>
                <Col sm={12} style={{ textAlign: "center" }}>
                  <Rating
                    name="size-large"
                    defaultValue={5}
                    size="large"
                    value={rating}
                    onChange={(event, newValue) => {
                      setRating(newValue);
                    }}
                  />
                </Col>
              </Row>
              <Form.Group>
                <Form.Label className="feedbackItem">
                  Tell us about your experience
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="10"
                  style={{ borderRadius: "10px", height: "100%" }}
                  onChange={(event) => {
                    setFeedbackDesc(event.target.value);
                  }}
                  required
                />
              </Form.Group>
              <Form.Check
                className="feedbackItem"
                type="checkbox"
                label="Would you like a representative to contact you?"
                onClick={(event) => {
                  setContact(event.target.checked);
                }}
              />
              {/* 
                <Reaptcha
                  sitekey="6Le2dTEjAAAAAFf5Wb5snydlv29LuQh0NkFnzXfc
                  "
                  render="explicit"
                  verifyCallback={verifyCallback}
                /> */}

              <Button
                type="submit"
                id="submitBtn"
                // disabled={!verified}
                className="feedbackItem"
              >
                Submit
              </Button>
            </Form>
          </div>
        </>
      )}
    </div>
  );
};

export default Feedback;
