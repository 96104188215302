import { faBook } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "aos/dist/aos.css";

import React from "react";

import { Col, Container, Row } from "react-bootstrap";

import "../App.css";

const manualsInfo = [
  {
    title: "EC-TOUCH Installation Manual",
    description:
      "Compatible with ESL-2, ESL, Elite S, Elite S Lite & ESX Control Panels.",
    href: "/manuals/installer/EC-TOUCH Manual.pdf",
  },
  {
    title: "EC-LCD Installer Manual v1.0",
    description: "Full LCD Keypad for EC-i, EC-PCB, ESX-1 & ESX-2.",
    href: "/manuals/installer/EC-LCD Manual.pdf",
  },
  {
    title: "EC-KP Manual",
    description: "Slimline Hardwired Keypad for EC-i, ESL, Runner & ESX-V2.",
    href: "/manuals/installer/EC-KP Manual.pdf",
  },
  {
    title: "EC-i Web Browser, Update & Config Manual",
    description: "",
    href: "/manuals/installer/EC-i Web Browser, Update & Config Manual.pdf",
  },
  {
    title: "EC-i System Defaults",
    description: "",
    href: "/manuals/installer/EC-i System Defaults.pdf",
  },
  {
    title: "EC-i RS232 Protocol",
    description: "As of ESX-2/ECi firmware version 10.2.441, 5 November 2020",
    href: "/manuals/installer/EC-i RS232 Protocol.pdf",
  },
  {
    title: "EC-i KIT Simple Manual",
    description:
      "This Guide cover the basics functions & principles of the EC-i control panel.",
    href: "/manuals/installer/EC-i KIT Simple Manual.pdf",
  },
  {
    title: "EC-i Install Summary",
    description:
      "This manual relates to ECi control panels with software version V10.3.43 and above.",
    href: "/manuals/installer/EC-i Install Summary.pdf",
  },
  {
    title: "EC-i Full Install Manual",
    description: "",
    href: "/manuals/installer/EC-i Full Install Manual.pdf",
  },
  {
    title: "EC-i Bus Information",
    description: "",
    href: "/manuals/installer/EC-i Bus Information.pdf",
  },
  {
    title: "EC-i Access Control Manual",
    description: "",
    href: "/manuals/installer/EC-i Access Control Manual.pdf",
  },
  {
    title: "EC-i 4G Manual",
    description: "Plug in 4G APP/Dashboard/Monitoring module for EC or EC-i.",
    href: "/manuals/installer/EC-i 4G Manual.pdf",
  },
  {
    title: "EC-A2 REX Manual",
    description:
      "Connects directly to the EC or EC-i control panel keypad bus for integrated alarm & access control applications.",
    href: "/manuals/installer/EC-A2 REX Manual.pdf",
  },
];

const infinityManuals = [
  {
    title: "Infinity TX-1 manual",
    description:
      "Waterproof Wireless Button for EC-i, ESL, Runner & Infinity Output.",
    href: "/manuals/installer/Infinity Wireless/infinity TX-1 manual.pdf",
  },
  {
    title: "Infinity Siren manual",
    description: "Wireless external Siren for EC-i, ESL, Runner Series.",
    href: "/manuals/installer/Infinity Wireless/infinity siren manual.pdf",
  },
  {
    title: "Infinity Remote Manual",
    description: "Wireless 4 Button Remote for EC-i, ESL & Runner.",
    href: "/manuals/installer/Infinity Wireless/infinity remote manual.pdf",
  },
  {
    title: "Infinity Panic Manual",
    description:
      "Waterproof Panic Button for EC-i, ESL & Runner & Infinity Output.",
    href: "/manuals/installer/Infinity Wireless/infinity panic manual.pdf",
  },
  {
    title: "Infinity Output Manual",
    description: "1CH Wireless Output for EC-i, ESL & Runner Series.",
    href: "/manuals/installer/Infinity Wireless/infinity output manual.pdf",
  },
  {
    title: "Infinity Motion Manual",
    description: "Wireless Motion Sensor for EC-i, ESL & Runner Series.",
    href: "/manuals/installer/Infinity Wireless/infinity motion manual.pdf",
  },
  {
    title: "Infinity Motion IP54 Manual",
    description:
      "Wireless Outdoor Sensor for EC-i, ESL & Runner Series. Wide Angle Dual Tech Microwave/PIR.",
    href: "/manuals/installer/Infinity Wireless/infinity motion IP54 manual.pdf",
  },
  {
    title: "Infinity Link Manual",
    description: "Wireless Transceiver for EC-i, ESL & Runner Control Panels.",
    href: "/manuals/installer/Infinity Wireless/infinity link manual.pdf",
  },
  {
    title: "Infinity Input Manual",
    description:
      "Wireless Door/Window Contact & Input for EC-i, ESL & Runner Control Panels.",
    href: "/manuals/installer/Infinity Wireless/infinity input manual.pdf",
  },
  {
    title: "Infinity Advanced Guide",
    description: "Infinity Advance Setup & Troubleshooting Guide.",
    href: "/manuals/installer/Infinity Wireless/Infinity Advanced Guide.pdf",
  },
];

const videos = [
  {
    title: "EliteCloud Systems Videos",
    href: "https://www.youtube.com/@elitecloudsystems",
  },
];

export default function Manuals() {
  return (
    <Container fluid>
      <Container className="text-center" style={{ marginTop: "80px" }}>
        <Row>
          <Col xs={12}>
            <h1 className="landhd alignVertical">
              <FontAwesomeIcon icon={faBook} className="ec-icon" />
              &nbsp;&nbsp;Manuals
            </h1>
          </Col>
        </Row>
      </Container>
      <Container style={{ marginTop: "80px" }}>
        <Row>
          {manualsInfo.map((manual, index) => (
            <Col key={index} xs={12}>
              <a className="landNte" href={manual.href}>
                <h5 className="primary-text">
                  {manual.title}
                  <span className="secondary-text">
                    &nbsp;&nbsp;{manual.description}
                  </span>
                </h5>
              </a>
            </Col>
          ))}
        </Row>
      </Container>
      <Container>
        <Row>
          <Col xs={12}>
            <h4
              style={{
                marginTop: "1.5rem",
                marginBottom: "1.5rem",
                color: "#4980be",
              }}
            >
              Infinity Wireless Manuals
            </h4>
          </Col>
        </Row>
        <Row>
          {infinityManuals.map((manual, index) => (
            <Col key={index} xs={12}>
              <a className="landNte" href={manual.href}>
                <h5 className="primary-text">
                  {manual.title}
                  <span className="secondary-text">
                    &nbsp;&nbsp;{manual.description}
                  </span>
                </h5>
              </a>
            </Col>
          ))}
        </Row>
      </Container>
      <Container>
        <Row>
          <Col xs={12}>
            <h4
              style={{
                marginTop: "1.5rem",
                marginBottom: "1.5rem",
                color: "#4980be",
              }}
            >
              Videos
            </h4>
          </Col>
        </Row>
        <Row>
          {videos.map((video, index) => (
            <Col key={index} xs={12}>
              <a
                className="landNte"
                href={video.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                <h5 className="primary-text">{video.title}</h5>
              </a>
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
}
