import "bootstrap/dist/css/bootstrap.min.css";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import "./App.css";

import BannerMessage from "./Component/BannerMessage";
import Feedback from "./Component/Feedback";
import Home from "./Component/Home";
import Layout from "./Component/Layout";
import Manuals from "./Component/Manuals";
import Upgrade from "./Component/Upgrade";
import useServerStatus from "./hooks/useServerStatus";

function App() {
  const { message } = useServerStatus();

  return (
    <>
      {message !== null && <BannerMessage message={message} />}
      <Router>
        <Switch>
          <Layout message={message}>
            <Route exact path={"/"} render={() => <Home message={message} />} />
            <Route exact path={"/manuals/installer"} component={Manuals} />
            <Route
              exact
              path={"/upgrade"}
              render={() => <Upgrade message={message} />}
            />
            <Route
              exact
              path={"/feedback"}
              render={() => <Feedback message={message} />}
            />
          </Layout>
          {/* <Route exact path={"/scroll"} component={ScrollIndicator} />
          <Route exact path={"/pricing"} component={Pricing} />
          <Route exact path={"/appslider"} component={AppSlider} />
          <Route exact path={"/mail"} component={EmailPops} /> */}
        </Switch>
      </Router>
    </>
  );
}

export default App;
