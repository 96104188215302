import React from "react";

const BannerMessage = ({ message }) => {
  return (
    <div className="banner">
      <div className="text-center">
        <h3 className="bannerText">{message}</h3>
      </div>
    </div>
  );
};

export default BannerMessage;
