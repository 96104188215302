import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import home from "../Img/home.png";
import notify from "../Img/notify.png";
import UserAdmin from "../Img/UserAdmin.png";
import history from "../Img/history.png";
import Controls from "../Img/Control.png";
import sensors from "../Img/sensors.png";
import enhancement from "../Img/enhancement.png";
import cloudIcon from "../Img/cloudIcon.png";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import { FaGooglePlay } from "react-icons/fa";
import { FaApple } from "react-icons/fa";

const items = [
  {
    id: 1,
    caption: "Home",
    Text: `Alarm status & control.\n\nArm, stay arm or disarm your alarm system from almost anywhere. EliteCloud provides a secure, bank level encrypted connection from almost any smart device to your home or business.`,
  },
  {
    id: 2,
    caption: "Notify",
    Text: "Alarm & alert notifications are sent directly to your smartphone, whether you are home or away.\n\nNotifications are configurable within the  EliteCloud app including arm, disarm, intrusion, fire, perimeter breach & more.",
  },
  {
    id: 3,
    caption: "User Admin",
    Text: "Manage who can access your alarm system.\n\nInvite or restrict users to your home or business directly from the app. Adding additional users is made simple by scanning their unique QR code into your EliteCloud app.",
  },
  {
    id: 4,
    caption: "History",
    Text: "Analysis from the cloud.\n\nAccess all alarm history including who armed or disarmed your system, sensor activations & even which doors were opened or closed. Each history event includes an associated time & date to accurately review & analyse your data.",
  },
  {
    id: 5,
    caption: "Control",
    Text: "EliteCloud is not just an alarm interface.\n\nDoors, gates, lights & more can be connected to the cloud and controlled from your smartphone. Allow staff, friends, family, couriers or customers access to your home, business or rented space from almost anywhere.",
  },
  {
    id: 6,
    caption: "Sensors",
    Text: "Always watching your assets.\n\nInformation from each sensor is sent to the cloud & displayed in real time on the EliteCloud app. Windows & doors show as open/closed, along with any movement of occupants or intruders. Any sensor can also be turned off from the app should your security requirements temporarily change.",
  },
  {
    id: 7,
    caption: "Enhancement",
    Text: "EliteCloud is designed, developed & maintained by our passionate team of engineers & testers at our premises in Millwater, New Zealand.\n\nThis passion ensures continuous growth in everything we create & we can't wait to share the journey with you.",
  },
];
const ImgSlides = [
  {
    id: 1,
    img: home,
    altText: "home",
  },
  {
    id: 2,
    img: notify,
    altText: "notify",
  },
  {
    id: 3,
    img: UserAdmin,
    altText: "UserAdmin",
  },
  {
    id: 4,
    img: history,
    altText: "history",
  },
  {
    id: 5,
    img: Controls,
    altText: "controls",
  },
  {
    id: 6,
    img: sensors,
    altText: "sensors",
  },
  {
    id: 7,
    img: enhancement,
    altText: "enhancement",
  },
];

const AppSlider = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        className="carousel"
        tag="div"
        key={item.id}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <CarouselCaption captionHeader={item.caption} captionText={item.Text} />
      </CarouselItem>
    );
  });
  const Imgslides = ImgSlides.map((ImgSlide) => {
    return (
      <CarouselItem
        className="carousel"
        tag="div"
        key={ImgSlide.id}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <img
          src={ImgSlide.img}
          alt="imgslider"
          data-aos="fade-up-left"
          data-aos-once="true"
        />
      </CarouselItem>
    );
  });

  return (
    <div>
      <Container>
        <Row className="appPricng">
          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}></Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
            <div className="appCarousel">
              <h1>
                <img src={cloudIcon} width="50px" alt="icon" />
                &nbsp;&nbsp;In Your Hand
              </h1>{" "}
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
            <Carousel
              activeIndex={activeIndex}
              next={next}
              previous={previous}
              pause={false}
              ride="carousel"
              interval="20000"
              className="carousel-fade faAppimg"
            >
              {Imgslides}
            </Carousel>
            <div className="flex-container">
              <Link
                to={{ pathname: "https://apps.apple.com/nz/app/elitecloud/id1508242548" }}
                target="_blank"
              >
                <div className=" flex-boss">
                  <div className="flex-child1 green">
                    <FaApple
                      style={{
                        fontSize: "37px",
                        marginTop: "-5px",
                        marginLeft: "-1px",
                      }}
                    />
                  </div>
                  <div className="flex-child green">
                    <span style={{ fontSize: "9px" }}> Download on the</span>
                    <br /> App Store
                  </div>
                </div>
              </Link>
              <Link
                to={{
                  pathname:
                    "https://play.google.com/store/apps/details?id=nz.co.aap.elitecontrol",
                }}
                target="_blank"
              >
                <div className=" flex-boss">
                  <div className="flex-child1 green">
                    <FaGooglePlay />
                  </div>
                  <div className="flex-child green">
                    <span style={{ fontSize: "8px" }}>GET IT ON</span>
                    <br /> Google Play
                  </div>
                </div>
              </Link>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
            <Carousel
              activeIndex={activeIndex}
              next={next}
              slide={true}
              previous={previous}
              interval="20000"
              className="carousel slide"
            >
              {slides}
              <Col md={{ span: 2, offset: 3 }}>
                <CarouselControl
                  direction="prev"
                  directionText="Previous"
                  onClickHandler={previous}
                />
                <CarouselControl
                  direction="next"
                  directionText="Next"
                  onClickHandler={next}
                />
                <CarouselIndicators
                  items={items}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
              </Col>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AppSlider;
