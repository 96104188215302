import { useCallback, useEffect, useState } from "react";

export default function useServerStatus() {
  const [isHealthy, setIsHealthy] = useState(true);
  const [message, setMessage] = useState(null);

  const check = useCallback(async () => {
    const response = await fetch(
      "https://serverutils.api.elitecloud.co.nz/serverstatus/api/serverstatus/"
    );

    if (response.ok) {
      const data = await response.json();

      if (data && data.messages) {
        console.log("checking server status...", data.messages);

        if (Array.isArray(data.messages) && data.messages.length > 0) {
          const messageType = data.messages[0].message_type;
          const messageContent = data.messages[0].message;

          if (messageType && messageContent) {
            switch (messageType) {
              case "server_alert":
                setIsHealthy(false);
                setMessage(messageContent);
                return false;
              case "general_communication":
                setIsHealthy(true);
                setMessage(messageContent);
                return true;
              case "scheduled_maintenance":
                setIsHealthy(true);
                setMessage(messageContent);
                return true;
              default:
                break;
            }
          } else {
            console.log(
              "error checking server status: message type or content not found"
            );
          }
        } else {
          setIsHealthy(true);
          setMessage(null);
          return true;
        }
      } else {
        console.log("error checking server status: messages not found");
      }
    } else {
      console.log("error checking server status", response);
    }
  }, []);

  useEffect(() => {
    try {
      check();
    } catch (error) {
      console.log("error checking server status", error);
    }
  }, [check]);

  // check status periodically
  useEffect(() => {
    const interval = setInterval(() => {
      try {
        check();
      } catch (error) {
        console.log("error checking server status", error);
      }
    }, 1000 * 60 * 15);

    return () => clearInterval(interval);
  }, [check]);

  return {
    isHealthy,
    message,
  };
}
